// import { Navbar, Nav, NavItem, NavLink } from "reactstrap";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
// import { NavLink as RRNavLink } from "react-router-dom";
import { connect } from "react-redux";

// import { Route, Switch, Redirect } from "react-router-dom";

import { useEffect, useState } from "react";
// import axios from "axios";

import * as actions from "./store/actions/index";
import "./App.css";

import "./components/css/media.css";
import "./components/css/style.css";

// import CreatePost from "./components/admin/CreatePost";
// import CreateCategory from "./components/admin/CreateCategory";
// import ShowImages from "./components/admin/ShowImages";
// import Login from "./components/admin/Login";
import { IS_LOCAL } from "./consts/const";
// import routes from "./components/Routes";
import CMSRoutes from "./components/Routes";

function App(props) {
  document.removeEventListener("contextmenu", () => {});
  const [modal, setModal] = useState(false);
  // const [whichComponent, setComponent] = useState("show");

  // const onChangeComponentHandler = (component_name) => {
  //   setComponent(component_name);
  // };

  // const [auth, setAuth] = useState(false);
  // const onSetAuth = (auth) => {
  //   setAuth(auth);
  // };

  const [ready, setReady] = useState(false);

  useEffect(() => {
    // prepare catagories from database
    // const token = localStorage.getItem("token");
    // const header = {
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded",
    //     Secret: token,
    //   },
    // };
    // const fetchData = async () => {
    //   try {
    //     const result = await axios.get(
    //       `${BASE_URL}/cms-system/api/user/auth.php`,
    //       // `${BASE_URL}/cms-system/api/post/read.php`,
    //       header
    //     );

    //     console.log(result.data);
    //     onSetAuth(true);
    //   } catch (err) {
    //     onSetAuth(false);
    //   }
    // };

    // fetchData();

    const { is_auth } = props;
    is_auth();
    setTimeout(() => {
      setReady(true);
    }, 1000);
    console.log(props);
    return () => {
      document.addEventListener("contextmenu", (event) => {
        const path = window.location.pathname;
        const is_admin_path = path.includes("/admin");

        const href = window.location.href;
        const is_writings_path = href.includes("writings");
        // console.log("path", );
        if (is_admin_path || IS_LOCAL || is_writings_path) {
          return;
        }
        if (event.target.src) {
          event.preventDefault();

          if (!event.target.alt.includes("full-size-alt")) {
            console.log("event ==", event.target.alt);
            setModal(true);
            // alert("dd")
          }
        }
      });
    };
  });

  // const { ready } = this.state;
  // if (!ready) return <></>;
  // const routes = (
  //   <routes />
  // )
  // let routes = (
  //   <Switch>
  //     <Route path="/admin/post/add" component={CreatePost} />
  //     <Route path="/admin/post" component={ShowImages} />
  //     <Route path="/admin/category" component={CreateCategory} />
  //     <Route path="/admin/login" component={Login} />
  //     <Redirect to="/admin/post" />
  //   </Switch>
  // );

  // if (!this.props.isAuth) {
  //   routes = (
  //     <Switch>
  //       <Route path="/sign" component={Sign} />
  //       <Redirect to="/sign" />
  //     </Switch>
  //   );
  // }
  const {
    // is_auth,
    isAuthStatus,
    // logout,
    // history,
  } = props;

  // console.log(history);

  if (!ready) {
    return null;
  }
  return (
    <div className="app-container">
      {/* {isAuthStatus && (
        <Navbar color="light" light expand="md">
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink
                to={`/admin/post/add`}
                activeClassName="active"
                tag={RRNavLink}
              >
                Add Post
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to={`/admin/category`}
                activeClassName="active"
                tag={RRNavLink}
              >
                Category
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to={`/admin/post`}
                activeClassName="active"
                tag={RRNavLink}
              >
                Show
              </NavLink>
            </NavItem>
            <NavItem>
              {isAuthStatus ? (
                <NavLink
                  to={{ pathname: `/admin/logout`, logout }}
                  activeClassName="active"
                  tag={RRNavLink}
                >
                  Logout
                </NavLink>
              ) : (
                <NavLink
                  to={`/admin/login`}
                  activeClassName="active"
                  tag={RRNavLink}
                >
                  Login
                </NavLink>
              )}
            </NavItem>
          </Nav>
        </Navbar>
      )} */}
      <CMSRoutes isAuth={isAuthStatus} />
      <Modal isOpen={modal} className="app-modal-copyright">
        <ModalBody>Photography copyright is owned by Adityo Prakash </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setModal(false);
            }}
          >
            OK
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthStatus: state.user.isAuth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    is_auth: () => dispatch(actions.is_auth()),
    // logout: () => dispatch(actions.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

// export default App;
// export default withRouter(connect(mapStateToProps, actions)(App));
