import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  BrowserView,
  MobileView,
  isTablet,
  // isBrowser,
  // isMobile,
} from "react-device-detect";
import { Container, Row, Col } from "reactstrap";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import * as actions from "../../store/actions/index";

// import Menu from "./components/Menu";
// import Bio from "./components/Bio";
// import Foot from "./components/Foot";
import PhotoArtTopImg from "./components/PhotoArtTopImg";

import "./PhotoArt.css";
import "./Gallery.css";
import "./GallerySlideShow.css";
import "./components/mobile/GallerySlideShow.css";
import { BASE_URL } from "../../consts/const";

class GallerySliderShow extends React.Component {
  state = {
    home_index: null,
    items: [],
    index: 0,
    fullScreenState: false,
  };

  componentDidMount() {
    document.body.classList.add("home-body-without-minheight");

    console.log(this.props);
    console.log("===444slide show");
    const { get_posts } = this.props;
    get_posts();
    window.scrollTo(0, 0);
    document.addEventListener("keydown", this.moveImageByKeyFunc, false);

    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const index = +params.get("index");
    this.setState({
      home_index: index,
    });
    // const isLarge = params.get("large");

    // if (isLarge) {
    //   document.body.style.visibility = "hidden";
    //   setTimeout(() => {
    //     // document.body.style.display = "unset";
    //     document.body.style.visibility = "unset";
    //   }, 200);
    // }

    // if (isLarge) {
    //   this.enter_slide_show();
    // }
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.moveImageByKeyFunc, false);
    // if (this.state.intervalId) {
    //   clearInterval(this.state.intervalId);
    // }
    this.exit_slide_show();
  }

  exit_slide_show = () => {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  };
  enter_slide_show = () => {
    const intervalId = setInterval(() => {
      this.onClickImage(1);
    }, 5000);
    this.setState({ intervalId: intervalId });
  };

  moveImageByKeyFunc = (event) => {
    if (event.keyCode === 37 && this.state.fullScreenState) {
      // console.log(event.keyCode);
      this.onClickImage(0);
    }
    if (event.keyCode === 39 && this.state.fullScreenState) {
      // console.log(event.keyCode);
      this.onClickImage(1);
    }
  };
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.posts !== this.props.posts) {
      const { posts } = nextProps;
      const { location } = this.props;
      const params = new URLSearchParams(location.search);
      const cat_id = params.get("id");

      const items = posts.filter((post) => {
        return post.category_id === cat_id && post.delete !== "1";
      });

      const home_post_index = items.findIndex((post) => {
        return post.category_id === cat_id && post.gallery === "1";
      });
      console.log("okok", home_post_index);
      this.setState({
        // home_index: home_post_index,
        items,
      });
    }
    return true;
  }

  // if 0, move left, else move right
  onClickImage = (left_or_right) => {
    // console.log("left or right", left_or_right);
    this.setState((state) => {
      let new_index;
      const items_length = state.items.length;
      if (left_or_right === 0) {
        new_index = state.home_index - 1;
        if (new_index < 0) {
          new_index = items_length - 1;
        }
      } else {
        new_index = state.home_index + 1;
        // console.log("new_index", new_index);
        if (new_index >= items_length) {
          new_index = new_index % items_length;
        }
      }

      return { home_index: new_index };
    });
  };

  onChangeFullScreenState = (fullScreenState) => {
    this.setState({
      fullScreenState,
    });
  };

  child = React.createRef();
  onClick_zoomout = () => {
    this.child.current.zoomout();
    // console.log("this", this.child);
  };

  render() {
    const { posts } = this.props;
    const { items, home_index } = this.state;
    // console.log("uniq", items, posts, home_index);
    if (posts.length === 0 || items.length === 0) {
      return null;
    }
    if (this.state.home_index === null) {
      return null;
    }

    // console.log("uniq", items, posts, home_index);

    // var items = posts.slice(0, 3);
    // console.log("==2", posts);

    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const cat_id = params.get("id");

    // console.log("home_index test", home_index);
    // const { first_show_sliders } = this.props;
    // console.log("first_show_sliders:", first_show_sliders);
    return (
      <>
        {!isTablet && (
          <BrowserView>
            <Container className="home-container normal">
              {/* <Row>
            <Col className="center gallery-header">
              {items[home_index].category_name}
            </Col>
          </Row> */}
              <Row className="gallery-row display-row slide-show">
                <Col xl="8" className="container img-container">
                  <PhotoArtTopImg
                    src={`${BASE_URL}/cms-system/uploads/${items[home_index].url}`}
                    nav_img_onclick={this.onClickImage}
                    onChanceFullScreenHandler={this.onChangeFullScreenState}
                    ref={this.child}
                    enlarge_first={this.state.large}
                    exit_slide_show={this.exit_slide_show}
                    enter_slide_show={this.enter_slide_show}
                    index={home_index}
                    items_a={items[home_index]}
                  />
                </Col>
                <Col xl="1"></Col>
                <Col xl="3" className="container desc ">
                  <div className="left-desc-div vertical-center">
                    {/* <div className="gallery-slide-show-category">
                  {items[home_index].category_name}
                </div> */}
                    <div className="gallery-slide-show-title">
                      {items[home_index].title}
                    </div>
                    <div className="gallery-slide-show-content-and-location-div">
                      <div className="gallery-slide-show-content">
                        {items[home_index].body}
                      </div>
                      <div className="gallery-slide-show-location">
                        {items[home_index].location_name}
                      </div>
                    </div>
                    <div className="row gallery-slide-show-div">
                      <div className="col-md-3">
                        <span
                          className="material-icons"
                          onClick={() => this.onClickImage(0)}
                        >
                          arrow_back_ios
                        </span>
                      </div>
                      <div className="col-md-3">
                        <span
                          className="material-icons"
                          onClick={() => this.onClickImage(1)}
                        >
                          arrow_forward_ios
                        </span>
                      </div>
                      <div className="col-md-3">
                        <Link to={`/photo-art/gallery?id=${cat_id}`}>
                          <span className="material-icons">reply</span>
                        </Link>
                      </div>
                      <div className="col-md-3">
                        <span
                          className="material-icons"
                          // onClick={this.onClick_zoomout}
                          onClick={() => {
                            this.onClick_zoomout();
                            // this.enter_slide_show();
                          }}
                        >
                          zoom_out_map
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              {/* <button onClick={this.onClick}>Click</button> */}
            </Container>
          </BrowserView>
        )}
        <MobileView>
          <div id="mobile-gallery-slide-show-main-div">
            <div className="margin-top-mobileview"></div>
            <div>
              <PhotoArtTopImg
                src={`${BASE_URL}/cms-system/uploads/${items[home_index].url}`}
                nav_img_onclick={this.onClickImage}
                onChanceFullScreenHandler={this.onChangeFullScreenState}
                ref={this.child}
                enlarge_first={this.state.large}
                exit_slide_show={this.exit_slide_show}
                enter_slide_show={this.enter_slide_show}
                index={home_index}
                items_a={items[home_index]}
              />
              <div className="gallery-slide-show-title">
                {items[home_index].title}
              </div>

              <div className="gallery-slide-show-body">
                {items[home_index].body}
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />

            <div className="gallery-slide-show-controller">
              <div>
                <div className="inline">
                  <span
                    className="material-icons"
                    onClick={() => this.onClickImage(0)}
                  >
                    arrow_back_ios
                  </span>
                </div>
                <div className="inline">
                  <span
                    className="material-icons"
                    onClick={() => this.onClickImage(1)}
                  >
                    arrow_forward_ios
                  </span>
                </div>
                <div className="inline">
                  <Link to={`/photo-art/gallery?id=${cat_id}`}>
                    <span className="material-icons">reply</span>
                  </Link>
                </div>
                <div className="inline">
                  <span
                    className="material-icons"
                    // onClick={this.onClick_zoomout}
                    onClick={() => {
                      this.onClick_zoomout();
                      // this.enter_slide_show();
                    }}
                  >
                    zoom_out_map
                  </span>
                </div>
              </div>
            </div>
          </div>
        </MobileView>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    posts: state.post.posts,
    // first_show_sliders_1: state.message.first_show_sliders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_posts: () => dispatch(actions.get_posts()),
    delete_post: (payload) => dispatch(actions.delete_post(payload)),
    update_post: (payload) => dispatch(actions.update_post(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GallerySliderShow);
