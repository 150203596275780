import React from "react";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import { Route, Switch, Redirect } from "react-router-dom";

import // BrowserView,
// MobileView,
// isTablet,
// isBrowser,
// isMobile,
"react-device-detect";

import Tools from "../../utils/tools";
import * as actions from "../../store/actions/index";

import MobileMenu from "./components/mobile/mMenu";

import Menu from "./components/Menu";
import Foot from "./components/Foot";

// import PhotoArt from "../components/PhotoArt";
// import WriteUp from "../components/WriteUp";
import PhotoArt from "./PhotoArt";
// import WriteUp from "./WriteUp";
import Gallery from "./Gallery";
import GallerySliderShow from "./GallerySlideShow";
import Home from "./Home";

// writings
// import WritingsHome from "./components/writings/WritingsIndex";
import WritingsByCatotry from "./components/writings/WritingsByCatotry";
import Writing from "./components/writings/Writing";

class Index extends React.Component {
  state = {
    is_desktop: true,
  };
  componentDidMount() {
    const { get_posts, get_writings } = this.props;
    get_posts();
    get_writings();
    document.body.style.backgroundColor = "#171717";
    // console.log("window.innerWidth", Tools.GetWindowWidth());
    this.setState({
      is_desktop: !Tools.is_less_than(1000),
    });
    window.addEventListener("resize", () => {
      // console.log(Tools.GetWindowWidth());
      this.setState({
        is_desktop: !Tools.is_less_than(1000),
      });
    });
  }

  render() {
    const { is_gallery } = this.props;
    const { is_desktop } = this.state;
    // const path = window.location.pathname;
    // const has_footer = !path.includes("gallery/slideshow");
    const has_footer = is_gallery;

    let menu_html = is_desktop ? (
      <Container className="home-container normal">
        <Menu />
      </Container>
    ) : (
      <MobileMenu />
    );

    // console.log("window.location", window.location.host);

    let switch_html = (
      <Switch>
        <Route exact path="/" component={Home} />
        <Redirect to="/" />
      </Switch>
    );
    if (Tools.is_photoart_domain()) {
      switch_html = (
        <Switch>
          <Route
            path="/photo-art/gallery/slideshow"
            component={GallerySliderShow}
          />
          <Route path="/photo-art/gallery" component={Gallery} />
          <Route path="/photo-art" component={PhotoArt} />
          <Route exact path="/" component={PhotoArt} />
          <Redirect to="/" />
        </Switch>
      );
    }

    if (Tools.is_writings_domain()) {
      switch_html = (
        <Switch>
          <Route path="/writings/:id/:w_id" component={Writing} />
          <Route path="/writings/:id" component={WritingsByCatotry} />
          <Route path="/writings" component={WritingsByCatotry} />
          {/* <Route path="/writings" component={WritingsHome} /> */}

          {/* <Route path="/write-up" component={Home} /> */}

          <Route exact path="/" component={WritingsByCatotry} />
          <Redirect to="/" />
        </Switch>
      );
    }

    return (
      <div className="home-body">
        {/* {!isTablet && (
          <BrowserView>
            <Container className="home-container normal">
              <Menu />
            </Container>
          </BrowserView>
        )}
        <MobileView>
          <MobileMenu />
        </MobileView> */}
        {menu_html}

        {/* <Switch>
          <Route path="/writings/:id/:w_id" component={Writing} />
          <Route path="/writings/:id" component={WritingsByCatotry} />
          <Route path="/writings" component={WritingsHome} />

          <Route path="/write-up" component={Home} />
          <Route
            path="/photo-art/gallery/slideshow"
            component={GallerySliderShow}
          />
          <Route path="/photo-art/gallery" component={Gallery} />
          <Route path="/photo-art" component={PhotoArt} />
          <Route path="/" component={Home} />
        </Switch> */}
        {switch_html}

        {has_footer && (
          <Container className="home-container normal">
            <Foot />
          </Container>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    is_gallery: state.message.is_gallery,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    get_posts: () => dispatch(actions.get_posts()),
    get_writings: () => dispatch(actions.get_writings()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
