import React from "react";
import { connect } from "react-redux";
import // BrowserView,
// MobileView,
// isTablet,
// isBrowser,
// isMobile,
"react-device-detect";
import { Container, Row, Col } from "reactstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import * as actions from "../../store/actions/index";

// import Menu from "./components/Menu";
import Bio from "./components/PhotoArtBio";
import MobilePhotoArtBio from "./components/mobile/PhotoArtBio";
// import Foot from "./components/Foot";
// import PhotoArtTopImg from "./components/PhotoArtTopImg";

import "./PhotoArt.css";
import "./components/mobile/PhotoArt.css";
import "../../components/css/media/photo-art.css";
// import bio_image_background from "../../images/bio-image-background.png";
import { BASE_URL } from "../../consts/const";
import { Link } from "react-router-dom";

import Tools from "../../utils/tools";

class PhotoArt extends React.Component {
  state = {
    is_visible: false,
  };

  componentDidMount() {
    // go to top start
    // var scrollComponent = this;
    // document.addEventListener("scroll", function (e) {
    //   scrollComponent.toggleVisibility();
    // });
    document.addEventListener("scroll", this.toggleVisibility_fn);
    // go to top end

    console.log(this.props);
    console.log("===444");
    const { get_posts } = this.props;
    get_posts();
  }

  componentWillUnmount() {
    // document.removeEventListener("scroll", () => {}, false);
    document.removeEventListener("scroll", this.toggleVisibility_fn);
  }

  toggleVisibility_fn = () => {
    var scrollComponent = this;
    scrollComponent.toggleVisibility();
  };

  // responsive = {
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 2,
  //     slidesToSlide: 1, // optional, default to 1.
  //   },
  //   tablet_1: {
  //     breakpoint: { max: 1024, min: 768 },
  //     items: 3,
  //     slidesToSlide: 1, // optional, default to 1.
  //   },
  //   tablet: {
  //     breakpoint: { max: 767, min: 464 },
  //     items: 2,
  //     slidesToSlide: 1, // optional, default to 1.
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 1,
  //     slidesToSlide: 1, // optional, default to 1.
  //   },
  // };

  create_responsive = (number) => {
    const item_number = number > 3 ? 3 : number;
    return {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: item_number,
        slidesToSlide: 1, // optional, default to 1.
      },
      tablet_1: {
        breakpoint: { max: 1024, min: 768 },
        items: item_number,
        slidesToSlide: 1, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 767, min: 464 },
        items: 2,
        slidesToSlide: 1, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
    };
  };

  create_comparator = (key) => {
    return (a, b) => {
      let val_a = a[key];
      let val_b = b[key];

      if (val_a < val_b) {
        return 1;
      }
      if (val_a > val_b) {
        return -1;
      }
      return 0;
    };
  };

  sortByID = () => {
    return (a, b) => {
      if (a.id === "1" && b.id !== "1") {
        return -1;
      }
      return 0;
    };
  };

  toggleVisibility() {
    if (window.pageYOffset > 300) {
      this.setState({
        is_visible: true,
      });
    } else {
      this.setState({
        is_visible: false,
      });
    }
  }
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    const { is_visible } = this.state;
    const { posts } = this.props;
    if (posts.length === 0) {
      return null;
    }

    // const items = posts.reduce((new_ary, current) => {
    //   const x = new_ary.find(
    //     (item) => item.category_id === current.category_id
    //   );
    //   if (!x) {
    //     return new_ary.concat([current]);
    //   } else {
    //     return new_ary;
    //   }
    // }, []);
    const items = posts.filter((post) => {
      return (
        post.gallery === "1" &&
        post.delete !== "1" &&
        post.category_id !== "1" &&
        post.location_id !== "1"
      );
    });

    const home_post = posts.find((post) => {
      return post.home === "1";
    });

    items.sort(this.create_comparator("category_display_id"));
    items.sort(this.sortByID());

    // console.log("uniq", items, posts);

    // var items = posts.slice(0, 3);
    // console.log("==2", posts);
    let desktop_html = (
      <>
        <Container className="home-container photo-art photo-art-display">
          <Row className="photo-art-row display-row">
            <Col xl="12" className="photo-art-col-display container">
              <div
                className="photo-art-main-image"
                style={{
                  backgroundImage: `url("${BASE_URL}/cms-system/uploads/${home_post.url}")`,
                }}
              ></div>
              <div className="photo-art-small-text-div">PHOTO ART</div>
              <div className="photo-art-large-text-div">PHOTO ART</div>
            </Col>
          </Row>
          <Row className="photo-art-row categories-row ">
            <Col xl="12" className="container">
              <Row>
                {/* <Col xl=""></Col> */}
                <Col xl="12" className="galleries-title">
                  GALLERIES
                </Col>
                {/* <Col xl="1"></Col> */}
              </Row>
              <Row className="galleries-list-row">
                {/* <Col xl="1"></Col> */}
                <Col xl="12" className="galleries-list">
                  <Carousel
                    responsive={this.create_responsive(
                      items ? items.length : 2
                    )}
                  >
                    {items.map((post) => {
                      return (
                        <React.Fragment key={post.id}>
                          <Link
                            to={`/photo-art/gallery?id=${post.category_id}`}
                          >
                            <div className="photo-art-inner-box">
                              <div
                                className="category-list-image-background "
                                style={{
                                  backgroundImage: `url(${BASE_URL}/cms-system/uploads/${post.url})`,
                                }}
                              ></div>
                            </div>
                            <div className="category-title">
                              {post.category_name}
                            </div>
                          </Link>
                        </React.Fragment>
                      );
                    })}
                  </Carousel>
                </Col>
                {/* <Col xl="1"></Col> */}
              </Row>
            </Col>
          </Row>
        </Container>
        <Container className="home-container normal photo-art-bio">
          <br />
          <br />
          <br />
          {/* <Bio /> */}
          {Tools.is_less_than(1200) ? <MobilePhotoArtBio /> : <Bio />}
        </Container>
      </>
    );
    let mobile_html = (
      <div id="mobile-photo-art-main-div">
        <div className="margin-top-mobileview" />
        <div className="box-title">PHOTO ART</div>
        <div
          className="photo-art-main-image"
          style={{
            backgroundImage: `url("${BASE_URL}/cms-system/uploads/${home_post.url}")`,
          }}
        ></div>
        <div className="galleries-section">
          <div className="box-title">GALLERIES</div>

          {items.map((post) => {
            return (
              <React.Fragment key={post.id}>
                <Link to={`/photo-art/gallery?id=${post.category_id}`}>
                  <img
                    src={`${BASE_URL}/cms-system/uploads/${post.url}`}
                    className="galleries-img"
                    alt="galleries "
                  />
                  <div className="category-title">{post.category_name}</div>
                </Link>
              </React.Fragment>
            );
          })}
        </div>
        <MobilePhotoArtBio />
        {/* <div className="bio-text">
          <br />
          <br />

          <img
            src={bio_image_background}
            alt="bio profile"
            className="bio-img-background"
          />
          <p>
            Too often we look but do not see. Doing photography makes me look at
            the world more intently, find interplay of forms, shapes and colors
            that give me joy. Processing the resulting two-dimensional images
            allows me creative expression of the world I see, evokes emotions
            latent in a scene, and transports me to a different state of mind. I
            hope you’ll enjoy the galleries. Perhaps my images might elicit some
            of those same feelings in you.
          </p>
          <p>
            I have been doing photography since 2017. By some measure that is
            not a very long time and what I express through my photo art keeps
            evolving, as it should. People often ask what equipment I use. Now
            more than ever, I appreciate the answer many a fine art photographer
            gives; it is not the camera but the eye. Although, that is not the
            whole truth. There are things that you simply cannot capture using a
            mobile phone or a mediocre camera or lens.
          </p>
          <p>
            So here goes: Cameras Sony a7rii and a7riii; Glass: 16-35, 24-70,
            70-200, 100-400 mm Sony GM zoom lenses, 1.4x teleconverter, plus a
            few primes like the 14mm Samyang, 35mm f2.8 Sony, 50mm f0.95
            Mitakon. And finally, let’s not forget the requisite editing tools
            like Photoshop. I use Lightroom as an asset management tool. While I
            am used to it, I think it stinks as much as any piece of software
            can. I have been thinking of switching to something else, perhaps
            Capture One; again, mostly to be used as an initial raw converter
            and an asset management tool.
          </p>
        </div> */}
        <div className="scroll-to-top">
          {is_visible && (
            <div onClick={() => this.scrollToTop()}>
              <span className="material-icons">expand_circle_down</span>
            </div>
          )}
        </div>
      </div>
    );

    if (!Tools.is_less_than(1000)) {
      return <>{desktop_html}</>;
    } else {
      return <>{mobile_html}</>;
    }
    // return (
    //   <>

    //     {!isTablet && (
    //       <BrowserView>
    //         <Container className="home-container photo-art photo-art-display">
    //           <Row className="photo-art-row display-row">
    //             <Col xl="12" className="photo-art-col-display container">
    //               <div
    //                 className="photo-art-main-image"
    //                 style={{
    //                   backgroundImage: `url("${BASE_URL}/cms-system/uploads/${home_post.url}")`,
    //                 }}
    //               ></div>
    //               <div className="photo-art-small-text-div">PHOTO ART</div>
    //               <div className="photo-art-large-text-div">PHOTO ART</div>
    //             </Col>
    //           </Row>
    //           <Row className="photo-art-row categories-row ">
    //             <Col xl="12" className="container">
    //               <Row>
    //                 <Col xl="12" className="galleries-title">
    //                   GALLERIES
    //                 </Col>
    //               </Row>
    //               <Row className="galleries-list-row">
    //                 <Col xl="12" className="galleries-list">
    //                   <Carousel responsive={this.responsive}>
    //                     {items.map((post) => {
    //                       return (
    //                         <React.Fragment key={post.id}>
    //                           <Link
    //                             to={`/photo-art/gallery?id=${post.category_id}`}
    //                           >
    //                             <div className="photo-art-inner-box">
    //                               <div
    //                                 className="category-list-image-background "
    //                                 style={{
    //                                   backgroundImage: `url(${BASE_URL}/cms-system/uploads/${post.url})`,
    //                                 }}
    //                               ></div>
    //                             </div>
    //                             <div className="category-title">
    //                               {post.category_name}
    //                             </div>
    //                           </Link>
    //                         </React.Fragment>
    //                       );
    //                     })}
    //                   </Carousel>
    //                 </Col>
    //               </Row>
    //             </Col>
    //           </Row>
    //         </Container>
    //         <Container className="home-container normal">
    //           <br />
    //           <br />
    //           <br />
    //           <Bio />
    //         </Container>
    //       </BrowserView>
    //     )}
    //     <MobileView>
    //       <div id="mobile-photo-art-main-div">
    //         <div className="margin-top-mobileview" />
    //         <div className="box-title">PHOTO ART</div>
    //         <div
    //           className="photo-art-main-image"
    //           style={{
    //             backgroundImage: `url("${BASE_URL}/cms-system/uploads/${home_post.url}")`,
    //           }}
    //         ></div>
    //         <div className="galleries-section">
    //           <div className="box-title">GALLERIES</div>

    //           {items.map((post) => {
    //             return (
    //               <React.Fragment key={post.id}>
    //                 <Link to={`/photo-art/gallery?id=${post.category_id}`}>
    //                   <img
    //                     src={`${BASE_URL}/cms-system/uploads/${post.url}`}
    //                     className="galleries-img"
    //                     alt="galleries "
    //                   />
    //                   <div className="category-title">{post.category_name}</div>
    //                 </Link>
    //               </React.Fragment>
    //             );
    //           })}
    //         </div>
    //         <div className="bio-text">
    //           <br />
    //           <br />

    //           <img
    //             src={bio_image_background}
    //             alt="bio profile"
    //             className="bio-img-background"
    //           />
    //           <p>
    //             Too often we look but do not see. Doing photography makes me
    //             look at the world more intently, find interplay of forms, shapes
    //             and colors that give me joy. Processing the resulting
    //             two-dimensional images allows me creative expression of the
    //             world I see, evokes emotions latent in a scene, and transports
    //             me to a different state of mind. I hope you’ll enjoy the
    //             galleries. Perhaps my images might elicit some of those same
    //             feelings in you.
    //           </p>
    //           <p>
    //             I have been doing photography since 2017. By some measure that
    //             is not a very long time and what I express through my photo art
    //             keeps evolving, as it should. People often ask what equipment I
    //             use. Now more than ever, I appreciate the answer many a fine art
    //             photographer gives; it is not the camera but the eye. Although,
    //             that is not the whole truth. There are things that you simply
    //             cannot capture using a mobile phone or a mediocre camera or
    //             lens.
    //           </p>
    //           <p>
    //             So here goes: Cameras Sony a7rii and a7riii; Glass: 16-35,
    //             24-70, 70-200, 100-400 mm Sony GM zoom lenses, 1.4x
    //             teleconverter, plus a few primes like the 14mm Samyang, 35mm
    //             f2.8 Sony, 50mm f0.95 Mitakon. And finally, let’s not forget the
    //             requisite editing tools like Photoshop. I use Lightroom as an
    //             asset management tool. While I am used to it, I think it stinks
    //             as much as any piece of software can. I have been thinking of
    //             switching to something else, perhaps Capture One; again, mostly
    //             to be used as an initial raw converter and an asset management
    //             tool.
    //           </p>
    //         </div>
    //         <div className="scroll-to-top">
    //           {is_visible && (
    //             <div onClick={() => this.scrollToTop()}>
    //               <span className="material-icons">expand_circle_down</span>
    //             </div>
    //           )}
    //         </div>
    //       </div>
    //     </MobileView>
    //   </>
    // );
  }
}

const mapStateToProps = (state) => {
  return {
    posts: state.post.posts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_posts: () => dispatch(actions.get_posts()),
    // delete_post: (payload) => dispatch(actions.delete_post(payload)),
    update_post: (payload) => dispatch(actions.update_post(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhotoArt);
