// export const BASE_URL = "http://192.168.0.222";
// export const BASE_URL = "http://192.168.0.241";
//  export const BASE_URL = "http://192.168.29.227";

// export const BASE_URL = "https://adityoprakash.com";
export const BASE_URL = "https://photoart.adityoprakash.com";

export const IS_LOCAL = false;

// const local_link = "http://localhost:";
const local_link = "http://192.168.0.241:";

export const INDEX_ROOT_URL = IS_LOCAL
  ? local_link + "3000/"
  : "https://www.adityoprakash.com/";
export const PHOTO_ART_ROOT_URL = IS_LOCAL
  ? local_link + "3001"
  : "https://photoart.adityoprakash.com";

export const WRITING_ROOT_URL = IS_LOCAL
  ? local_link + "3002"
  : "https://writings.adityoprakash.com";

// http://localhost:3001/photo-art
// export const IS_ROOT_DOMAIN = IS_LOCAL ?
