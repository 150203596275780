import React from "react";
import { Row, Col } from "reactstrap";
import "./HomeBio.css";

import Tools from "../../../utils/tools";
import "../../css/media/home-bio.css";

// import bio_image_01 from "../../../images/bio-image-01.png";

class HomeBio extends React.Component {
  render() {
    return (
      <>
        {/* <Row className="home-bio-row mt-5 pt-2">
          <Col xl="12">
            <div className="text-column">
              <p>
                Hello, and welcome to my place on the web. This is meant to be a
                waypoint from where you can head off to explore my photo art or
                writings.{" "}
              </p>
              <p>
                Don’t let me keep you from exploring further. If you wish to
                know a bit more about me here is a little narrative in my own
                words.
              </p>
            </div>
          </Col>
        </Row> */}

        <div className="home-bio-main-image-div photo-art-bio-main-div ">
          <Row className="p-row">
            {/* == */}
            <Col xl="12" className="mt-5">
              {/* <br /> */}
              <p>
                Hello, and welcome to my place on the web. This is meant to be a
                waypoint from where you can head off to explore my photo art or
                writings.{" "}
              </p>
              <p>
                Don’t let me keep you from exploring further. If you wish to
                know a bit more about me, here is a little narrative in my own
                words.
              </p>
            </Col>

            {/* == */}
            <Col xl={Tools.is_less_than(1400) ? "9" : "8"}>
              <p>
                While growing up, I thought I was going to be an academic
                pursuing a research career in theoretical physics. While in
                college I had the pleasure of interacting with one mathematics
                professor, Anatole Katok, who made me realize that I loved math
                even more than physics. Eventually as I started working on some
                research problems in mathematical physics, I began to notice
                that most of the bright kids I knew in the late eighties and
                early nineties were leaving academia for careers on Wall Street
                or in industry. A few years later Phil Anderson wrote a{" "}
                <a
                  className="bio-link-text"
                  href="https://physicstoday.scitation.org/doi/10.1063/1.882812"
                  target="_blank"
                  rel="noreferrer"
                >
                  nice article
                </a>{" "}
                about that in Physics Today.
              </p>
              <p>
                The expansion of the Internet was just starting, and I started
                discussing with a friend how to process video for more efficient
                delivery. It was a mathematically oriented problem whose
                solution, it seemed to me, could underpin the then emerging
                world of media delivery on the Internet. The problem also
                satisfied my criteria for what should form the basis for a
                business venture with large potential – exploration of an
                emerging field at the intersection of traditional business
                segments; in this case the intersection of computers, networking
                and traditional media and entertainment. I left academia to
                start my first company, Pulsent. We developed some interesting
                technologies that, according to various subject matter experts
                and pioneers, were the only breakthroughs to have come along in
                the field since the development of video processing and
                compression technology in the late seventies. Intel owns all
                that technology now and key parts of the transmission of all
                video on the Internet today rely on technology that we built.
              </p>

              <p>
                In the early aughts two of my colleagues and I were considering
                ideas for our next adventure. Working at the intersection of
                high-tech and biotech seemed like an interesting thing to do.
                What if we could build the modeling tools for constructing
                completely new drug molecules that bind to specific proteins in
                our bodies and change their functions? That idea has led us
                through a very long journey of developing many advancements in
                molecule engineering that today form the basis for{" "}
                <a
                  className="bio-link-text"
                  href="https://www.verseon.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Verseon
                </a>
                's drug design platform. Along the way, we are still going
                through the trials and tribulations of building a pharmaceutical
                company.
              </p>
              <p>
                If you have read this far, I only have one request of you. Judge
                my photography or writings on their own merits, without
                consideration for anything else I have done. Each of those
                endeavors should stand on its own as if that were the primary
                thing I do.
              </p>
            </Col>
            <Col xl="2"></Col>
          </Row>
        </div>

        {/* <Row className="home-bio-row">
          <Col xl="8">
            <div className="text-column pr-3">
              <p>
                While growing up, I thought I was going to be an academic
                pursuing a research career in theoretical physics. While in
                college I had the pleasure of interacting with one mathematics
                professor, Anatole Katok, who made me realize that I loved math
                even more than physics. Eventually as I started working on some
                research problems in mathematical physics, I began to notice
                that most of the bright kids I knew in the late eighties and
                early nineties were leaving academia for careers on Wall Street
                or in industry. A few years later Phil Anderson wrote a{" "}
                <a
                  className="bio-link-text"
                  href="https://physicstoday.scitation.org/doi/10.1063/1.882812"
                  target="_blank"
                  rel="noreferrer"
                >
                  nice article
                </a>{" "}
                about that in Physics Today.
              </p>
              <p>
                The expansion of the Internet was just starting and I started
                discussing with a friend how to process video for more efficient
                delivery. It was a mathematical problem whose solution, it
                seemed to me, could underpin the then emerging world of media
                delivery on the Internet. The problem also satisfied my criteria
                for what should form the basis for a business venture with large
                potential – exploration of an emerging field at the intersection
                of traditional business segments; in this case the intersection
                of computers, networking and traditional media and
                entertainment. I left academia to start my first company,
                Pulsent. We developed some interesting technologies that,
                according to various subject matter experts and pioneers, were
                the only breakthroughs to have come along in the field since the
                development of video processing and compression technology in
                the late seventies. Intel owns all that technology now and key
                parts of the transmission of all video on the Internet today
                rely on technology that we built.
              </p>
            </div>
          </Col>
          <Col xl="4">
            <div className="ml-3 bio-photo-div">
              <img src={bio_image_01} alt="bio profile" />
            </div>
          </Col>
        </Row> */}
        {/* {Tools.is_less_than(1400) && (
          <Row className="home-bio-row">
            <Col xl="11">
              <div className="text-column">
                <p>
                  In the early aughts two of my colleagues and I were
                  considering ideas for our next adventure. Working at the
                  intersection of high-tech and biotech seemed like an
                  interesting thing to do. What if we could build the modeling
                  tools for constructing completely new drug molecules that bind
                  to specific proteins in our bodies and change their functions?
                  That idea has led us through a very long journey of developing
                  many advancements in molecule engineering that today form the
                  basis for Verseon’s drug design platform. Along the way, we
                  are still going through the trials and tribulations of
                  building a pharmaceutical company.
                </p>
                <p>
                  If you have read this far, I only have one request of you.
                  Judge my photography or writings on their own merits, without
                  consideration for anything else I have done. Each of those
                  endeavors should stand on its own as if that is the primary
                  thing I do.
                </p>
              </div>
            </Col>
          </Row>
        )} */}
      </>
    );
  }
}

export default HomeBio;
