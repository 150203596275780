import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";

import * as actions from "../../../../store/actions/index";
import "./WritingsIndex.css";
import "./WritingsByCategory.css";
import "../../../css/media/writings/writings-home.css";
import { BASE_URL } from "../../../../consts/const";

import book_img from "../../../../images/writings/book.png";
import Tools from "../../../../utils/tools";
import { Link } from "react-router-dom";

class WritingsByCatotry extends Component {
  state = {
    is_visible: false,
  };
  componentDidMount() {
    // go to top start
    var scrollComponent = this;
    document.addEventListener("scroll", function (e) {
      scrollComponent.toggleVisibility();
    });
    // go to top end
    // const { get_writings } = this.props;
    // get_writings();
  }

  create_comparator = (key) => {
    return (a, b) => {
      let val_a = a[key];
      let val_b = b[key];

      if (val_a < val_b) {
        return 1;
      }
      if (val_a > val_b) {
        return -1;
      }
      return 0;
    };
  };

  sortByID = () => {
    return (a, b) => {
      if (a.id === "1" && b.id !== "1") {
        return -1;
      }
      return 0;
    };
  };

  toggleVisibility() {
    if (window.pageYOffset > 300) {
      this.setState({
        is_visible: true,
      });
    } else {
      this.setState({
        is_visible: false,
      });
    }
  }
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  render() {
    const body_length = Tools.is_less_than(1200) ? 130 : 240;
    const title_length = Tools.is_less_than(1200) ? 30 : 60;

    const { is_visible } = this.state;
    // const cat_id = this.props.match.params.id;
    const cat_id = this.props.match.params.id
      ? this.props.match.params.id
      : "1";
    const is_default = cat_id === "1";

    const { writings, writing_categories } = this.props;
    const writings_1 = writings.filter((item) => {
      return item.delete !== "1" && item.category_id === cat_id;
    });
    console.log("writings", writings_1, writing_categories);

    if (writings.length === 0) {
      return null;
    }

    const items = Tools.getUniqueArrayBy(writings_1, "category_id").filter(
      (writing) => {
        return (
          // writings.gallery === "1" &&
          writing.delete !== "1" && writing.category_id === cat_id
        );
      }
    );
    items.sort(this.create_comparator("category_display_id"));
    items.sort(this.sortByID());
    console.log("items", items);

    console.log("writing_categories", writing_categories);
    const current_category = items.find((cate) => cate.category_id === cat_id);
    console.log("current_category", current_category);

    let desktop_html = (
      <>
        <Container className="home-container photo-art photo-art-display writing-section">
          <Row className="photo-art-row display-row writing-section">
            <Col lg="12" className="photo-art-col-display container">
              <div className="photo-art-main-image writing-section">
                <Row className="writing-section-first-div">
                  <Col lg="6" className="writings-text-div">
                    <div className="main-sub-title-div">
                      <div className="main-title">WRITINGS</div>
                      <div className="sub-title-category">
                        {!is_default && current_category.category_name}
                      </div>
                    </div>
                  </Col>
                  <Col lg="6">
                    <img src={book_img} alt="book" />
                    {!is_default && (
                      <img
                        className="book-sub-image"
                        alt="book-sub"
                        src={`${BASE_URL}/cms-system/uploads/category_images/${current_category.category_image_url}`}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          {/* // */}

          <Row className="photo-art-row categories-row writing-section">
            <Col lg="12" className="container">
              <Row>
                <Col lg="12" className="galleries-title">
                  {/* GALLERIES */}
                </Col>
              </Row>
              <Row className="galleries-list-row">
                <Col lg="12" className="galleries-list">
                  <br />
                  <br />
                  <br />
                  {writings_1.map((writing) => {
                    const writing_title = writing.title;
                    const writing_body = Tools.parseMdForSummary(writing.body);
                    const writing_title_trim =
                      writing_title.length > title_length
                        ? writing_title.substring(0, title_length - 1) + "..."
                        : writing_title;

                    const writing_body_trim =
                      writing_body.length > body_length
                        ? writing_body.substring(0, body_length - 1) + "..."
                        : writing_body;
                    return (
                      <Link
                        to={`/writings/${writing.category_id}/${writing.id}`}
                        key={writing.id}
                      >
                        <Row key={writing.id}>
                          <Col lg="3">
                            {/* <img src={book_img} width="100%" alt="book_img" /> */}

                            <img
                              src={
                                writing.thumbnail ? writing.thumbnail : book_img
                              }
                              width="100%"
                              alt="book_img"
                              className="thumbnail-img"
                            />

                            {!is_default && (
                              <img
                                src={`${BASE_URL}/cms-system/uploads/category_images/${writing.category_image_url}`}
                                width="25px"
                                className="writing-category-icon-overlap"
                                alt="book_img_icon"
                              />
                            )}
                          </Col>
                          {/* {writing.category_image_url} */}
                          <Col lg="8">
                            {/* <Link
                            to={`/writings/${writing.category_id}/${writing.id}`}
                          > */}
                            <div className="writing-title-div">
                              {writing_title_trim}
                            </div>
                            <div className="writing-publish-date-div">{`${new Date(
                              writing.publish_at
                            ).toDateString()}`}</div>
                            <div className="writing-body-div">
                              {writing_body_trim}
                            </div>
                            {/* </Link> */}
                          </Col>
                        </Row>
                      </Link>
                    );
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
    let mobile_html = (
      <div id="mobile-photo-art-main-div" className="writings-section">
        <div className="margin-top-mobileview" />

        <div>
          {/* <Link to={`/writings`}>
            <span className="material-icons go-back-to-category-icon">
              reply
            </span>
          </Link> */}
          <div className="box-title">Writings</div>
          <div className="box-title sub-title">
            {!is_default && current_category.category_name}
          </div>
        </div>
        <div
          className="photo-art-main-image"
          style={{
            backgroundImage: `url(${book_img})`,
          }}
        >
          <div className="main-title">Writings</div>
          {!is_default && (
            <img
              className="book-sub-image"
              alt="book-sub"
              src={`${BASE_URL}/cms-system/uploads/category_images/${current_category.category_image_url}`}
            />
          )}
        </div>
        <div className="galleries-section">
          {writings_1.map((writing) => {
            const writing_title = writing.title;
            const writing_body = Tools.parseMdForSummary(writing.body);
            const writing_title_trim =
              writing_title.length > title_length
                ? writing_title.substring(0, title_length - 1) + "..."
                : writing_title;

            const writing_body_trim =
              writing_body.length > body_length
                ? writing_body.substring(0, body_length - 1) + "..."
                : writing_body;
            return (
              <div key={writing.id} className="mobile-article-list-div" >
                <Link to={`/writings/${writing.category_id}/${writing.id}`}>
                  <Row className="writings-list-row">
                    <Col xs="12" className="writing-title">
                      <img
                        src={writing.thumbnail ? writing.thumbnail : book_img}
                        width="100%"
                        alt="book_img"
                        className="thumbnail-img"
                      />
                    </Col>
                  </Row>
                  <Row className="writings-list-row">
                    <Col xs="12" className="writing-title">
                      {writing_title_trim}
                    </Col>
                  </Row>
                  <Row className="writings-list-row">
                    <Col xs="10" className="writing-date">
                      {`${new Date(writing.publish_at).toDateString()}`}
                    </Col>
                    <Col xs="1" className="writing-image">
                      {!is_default && (
                        <img
                          src={`${BASE_URL}/cms-system/uploads/category_images/${writing.category_image_url}`}
                          alt={writing.title}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row className="writings-list-row">
                    <Col xs="12" className="writing-body">
                      {writing_body_trim}
                    </Col>
                  </Row>
                </Link>
                <br />
              </div>
            );
          })}
        </div>
        <div className="scroll-to-top">
          {is_visible && (
            <div onClick={() => this.scrollToTop()}>
              <span className="material-icons">expand_circle_down</span>
            </div>
          )}
        </div>
      </div>
    );

    if (!Tools.is_less_than(1000)) {
      return <>{desktop_html}</>;
    } else {
      return <>{mobile_html}</>;
    }

    // return (
    //   <>
    //     <Container className="home-container photo-art photo-art-display writing-section">
    //       <Row className="photo-art-row display-row writing-section">
    //         <Col lg="12" className="photo-art-col-display container">
    //           <div className="photo-art-main-image writing-section">
    //             <Row className="writing-section-first-div">
    //               <Col lg="6" className="writings-text-div">
    //                 <div className="main-sub-title-div">
    //                   <div className="main-title">WRITINGS</div>
    //                   <div className="sub-title-category">
    //                     {current_category.category_name}
    //                   </div>
    //                 </div>
    //               </Col>
    //               <Col lg="6">
    //                 <img src={book_img} alt="book" />
    //                 <img
    //                   className="book-sub-image"
    //                   alt="book-sub"
    //                   src={`${BASE_URL}/cms-system/uploads/category_images/${current_category.category_image_url}`}
    //                 />
    //               </Col>
    //             </Row>
    //           </div>
    //         </Col>
    //       </Row>
    //       {/* // */}

    //       <Row className="photo-art-row categories-row ">
    //         <Col xl="12" className="container">
    //           <Row>
    //             <Col xl="12" className="galleries-title">
    //             </Col>
    //           </Row>
    //           <Row className="galleries-list-row">
    //             <Col xl="12" className="galleries-list">
    //               <br />
    //               <br />
    //               <br />
    //               {writings_1.slice(0, 5).map((writing) => {
    //                 return (
    //                   <Row key={writing.id}>
    //                     <Col xl="3">
    //                       <img src={book_img} width="100%" alt="book_img" />
    //                       <img
    //                         src={`${BASE_URL}/cms-system/uploads/category_images/${writing.category_image_url}`}
    //                         width="25px"
    //                         className="writing-category-icon-overlap"
    //                         alt="book_img_icon"
    //                       />
    //                     </Col>
    //                     <Col xl="8">
    //                       <Link
    //                         to={`/writings/${writing.category_id}/${writing.id}`}
    //                       >
    //                         <div className="writing-title-div">
    //                           {writing.title}
    //                         </div>
    //                         <div className="writing-publish-date-div">{`${new Date(
    //                           writing.publish_at
    //                         ).toDateString()}`}</div>
    //                         <div className="writing-body-div">
    //                           {writing.body.length > 260
    //                             ? writing.body.substring(0, 269) + "..."
    //                             : writing.body}
    //                         </div>
    //                       </Link>
    //                     </Col>
    //                   </Row>
    //                 );
    //               })}
    //             </Col>
    //           </Row>
    //         </Col>
    //       </Row>
    //     </Container>
    //   </>
    // );
  }
}

const mapStateToProps = (state) => {
  return {
    writings: state.writing.writings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_writings: () => dispatch(actions.get_writings()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WritingsByCatotry);
