import React from "react";
import { Row, Col } from "reactstrap";
import "./PhotoArtBio.css";

class PhotoArtBio extends React.Component {
  render() {
    return (
      <>
        <div className="photo-art-bio-main-div container">
          <Row>
            <Col xl="6"></Col>
            <Col xl="6">
              <p>
                Too often we look but do not see. Doing photography makes me
                look at the world more intently, find interplay of forms, shapes
                and colors that give me joy. Processing the resulting
                two-dimensional images allows me creative interpretation of the
                world I see, evokes emotions latent in a scene, and transports
                me to a different state of mind. I hope you’ll enjoy the
                galleries. Perhaps my images might elicit some of those same
                feelings in you.
              </p>
              <p>
                I have been doing photography since 2017. By some measure that
                is not a very long time and what I express through my photo art
                keeps evolving, as it should. People often ask what equipment I
                use. Now more than ever, I appreciate the answer many a fine art
                photographer gives; it is not the camera but the eye. Although,
                that is not the whole truth. There are things that you simply
                cannot capture using a mobile phone or a mediocre camera or
                lens.
              </p>
              <p>
                {/* So here goes: Cameras Sony a7rii and a7riii; Glass: 16-35,
                24-70, 70-200, 100-400 mm Sony GM zoom lenses, 1.4x
                teleconverter, plus a few primes like the 14mm Samyang, 35mm
                f2.8 Sony, 50mm f0.95 Mitakon. And finally, let’s not forget the
                requisite editing tools like Photoshop. I use Lightroom as an
                asset management tool. While I am used to it, I think it stinks
                as much as any piece of software can. I have been thinking of
                switching to something else, perhaps Capture One; again, mostly
                to be used as an initial raw converter and an asset management
                tool. */}
                So here goes: Cameras: Sony a7rii and a7riii; Glass: 16-35,
                24-70, 70-200, 100-400 mm Sony GM zoom lenses, 1.4x
                teleconverter, plus a few primes like the 14mm Samyang, 35mm
                f/2.8 Sony, 50mm f/0.95 Mitakon. And finally, let’s not forget
                the requisite editing tools like Photoshop. Creating fine art
                digital images doesn’t stop at clicking the shutter. I use
                Lightroom as an asset management tool. While I am used to it, I
                think it stinks as much as any piece of software can. I have
                been thinking of switching to something else, perhaps Capture
                One; again, mostly to be used as an initial raw converter and an
                asset management tool.
              </p>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default PhotoArtBio;
