import React, { Component } from "react";
import { connect } from "react-redux";
import { FileDrop } from "react-file-drop";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  Button,
  FormGroup,
  // Label,
  Input,
  // Container,
  Form,
  Label,
  Col,
  Modal,
  ModalBody,
  Spinner,
  ModalFooter,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import * as actions from "../../../store/actions/index";
import { BASE_URL } from "../../../consts/const";
import "./CreateWriting.css";

class CreateWriting extends Component {
  state = {
    title: "",
    subtitle: "",
    thumbnail: "",
    body: "",
    category: "1",
    is_modal_open: false,
    publish_at: new Date(),
    deleted_images: [],
  };

  componentDidMount() {
    const { get_writing_categories } = this.props;
    get_writing_categories();
    const { init_admin_upload_images_finished_array } = this.props;
    init_admin_upload_images_finished_array();
  }

  componentWillUnmount() {
    const { init_admin_upload_images_finished_array } = this.props;
    init_admin_upload_images_finished_array();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.admin_add_writing_status !== this.props.admin_add_writing_status
    ) {
      const { admin_add_writing_status } = nextProps;
      if (admin_add_writing_status === "pending") {
        this.setState({
          modal_componet: (
            <div>
              <Spinner type="grow" color="primary" />
              <Spinner type="grow" color="secondary" />
              <Spinner type="grow" color="success" />
              <Spinner type="grow" color="danger" />
              <Spinner type="grow" color="warning" />
              <Spinner type="grow" color="info" />
              <Spinner type="grow" color="light" />
              <Spinner type="grow" color="dark" />
            </div>
          ),
        });
      }
      if (admin_add_writing_status === "done") {
        this.setState({
          modal_componet: <div>Created successfully</div>,
        });
      }
      if (admin_add_writing_status === "failure") {
        this.setState({
          modal_componet: (
            <div>Something went wrong, please try again later</div>
          ),
        });
      }
    }
    return true;
  }

  onChange = (e) => {
    const { value, name } = e.target;
    this.setState({
      [name]: value,
    });
  };

  submitHandler = () => {
    const {
      title,
      subtitle,
      thumbnail,
      body,
      category,
      publish_at,
      deleted_images,
    } = this.state;
    const { admin_upload_images_finished_array, delete_image } = this.props;
    const images_field = admin_upload_images_finished_array.join(",");
    // console.log(typeof images_field);
    // console.log(images_field.split(","));

    // console.log(title, body);
    const payload = {
      title,
      subtitle,
      thumbnail,
      body,
      category_id: category,
      author: "",
      publish_at: `${publish_at.getFullYear()}-${
        publish_at.getMonth() + 1
      }-${publish_at.getDate()}`,
      image_urls: images_field,
    };
    console.log(payload);
    const { add_writing } = this.props;
    add_writing(payload);

    deleted_images.forEach((image) => {
      delete_image({ image });
    });
    this.setState({ is_modal_open: true });
  };

  render() {
    const {
      title,
      subtitle,
      thumbnail,
      body,
      category,
      is_modal_open,
      modal_componet,
    } = this.state;
    const {
      writing_categories,
      admin_upload_images_finished_array,
    } = this.props;

    console.log(
      "writing_category",
      writing_categories,
      admin_upload_images_finished_array
    );

    return (
      <>
        <div className="admin-images-list-main">
          <div className="admin-header mb-5">New writing</div>
          <Form>
            <FormGroup row>
              <Label for="title" sm={2}>
                Publish
              </Label>
              <Col sm={10}>
                <DatePicker
                  selected={this.state.publish_at}
                  onChange={(date) => this.setState({ publish_at: date })}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="exampleSelect" sm={2}>
                Category
              </Label>
              <Col sm={10}>
                <Input
                  type="select"
                  name="category"
                  id="exampleSelect"
                  value={category}
                  onChange={this.onChange}
                >
                  {writing_categories.map((category) => {
                    return (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    );
                  })}
                </Input>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="title" sm={2}>
                Title
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="title"
                  id="post-title"
                  placeholder="Enter title"
                  value={title}
                  onChange={this.onChange}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="subtitle" sm={2}>
                Sub Title
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="subtitle"
                  id="sub-title"
                  placeholder="Enter sub title"
                  value={subtitle}
                  onChange={this.onChange}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="thumbnail" sm={2}>
                Thumbnail
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="thumbnail"
                  id="thumbnail"
                  placeholder="Enter thumbnail url"
                  value={thumbnail}
                  onChange={this.onChange}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="title" sm={2}>
                Images
              </Label>
              <Col sm={10}>
                <div>
                  <FileDrop
                    onFrameDragEnter={(event) =>
                      console.log("onFrameDragEnter", event)
                    }
                    onFrameDragLeave={(event) =>
                      console.log("onFrameDragLeave", event)
                    }
                    onFrameDrop={(event) => console.log("onFrameDrop", event)}
                    onDragOver={(event) => console.log("===onDragOver", event)}
                    onDragLeave={(event) =>
                      console.log("===onDragLeave", event)
                    }
                    onDrop={(files, event) => {
                      console.log("onDrop!", files, event, files.length);
                      const allowedType = [
                        "image/png",
                        "image/jpeg",
                        "image/gif",
                      ];
                      [...files].forEach((image) => {
                        if (allowedType.includes(image.type)) {
                          const fileReader = new FileReader();
                          fileReader.readAsDataURL(image);
                          fileReader.onload = () => {
                            const { upload_image } = this.props;
                            const payload = {
                              file: image,
                            };
                            console.log("file_result", image);

                            upload_image(payload);
                          };
                        } else {
                          alert(
                            "Please only upload image files(gif, jpeg, png)"
                          );
                        }
                      });
                    }}
                  >
                    Drop image files here!
                  </FileDrop>
                </div>
                <div className="upload-image-div">
                  {admin_upload_images_finished_array &&
                    admin_upload_images_finished_array.map(
                      (image_name, index) => {
                        return (
                          <div className="upload-image-div-item">
                            <img
                              alt={index}
                              src={`${BASE_URL}/cms-system/uploads/writing/${image_name}`}
                              height="120px"
                              width="auto"
                            />
                            <CopyToClipboard
                              key={image_name}
                              text={`![100%](${BASE_URL}/cms-system/uploads/writing/${image_name})`}
                              onCopy={() => this.setState({ copied: true })}
                            >
                              <div
                                className="copy-to-clipboard"
                                title="Copy link to clipboard"
                              >
                                <span className="material-icons">
                                  content_copy
                                </span>
                              </div>
                            </CopyToClipboard>
                            <div
                              className="delete-selected-icon-div"
                              title="Delete"
                              onClick={() => {
                                const {
                                  admin_upload_images_finished_array,
                                  update_admin_uploaded_images_array,
                                } = this.props;

                                const image_name =
                                  admin_upload_images_finished_array[index];
                                this.setState(
                                  (prevState) => {
                                    const new_deleted_images = [
                                      ...prevState.deleted_images,
                                      image_name,
                                    ];
                                    return {
                                      ...prevState,
                                      deleted_images: new_deleted_images,
                                    };
                                  },
                                  () => {
                                    console.log("my_state", this.state);
                                  }
                                );
                                console.log(
                                  "admin_upload_images_finished_array",
                                  admin_upload_images_finished_array,
                                  index
                                );
                                admin_upload_images_finished_array.splice(
                                  index,
                                  1
                                );
                                update_admin_uploaded_images_array(
                                  admin_upload_images_finished_array
                                );
                              }}
                            >
                              <span className="material-icons">
                                delete_outline
                              </span>
                            </div>
                          </div>
                        );
                      }
                    )}
                </div>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="body" sm={2}>
                Description
              </Label>
              <Col sm={10}>
                <Input
                  type="textarea"
                  name="body"
                  id="post-body"
                  placeholder="Enter description"
                  value={body}
                  onChange={this.onChange}
                />
              </Col>
            </FormGroup>

            <div className="">
              <Button
                onClick={this.submitHandler}
                color="primary"
                disabled={title.trim().length < 2 || body.trim().length < 2}
              >
                CREATE
              </Button>
            </div>
          </Form>
        </div>
        <Modal isOpen={is_modal_open} className="admin-reorder-modal">
          <ModalBody>{modal_componet}</ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                this.setState({ is_modal_open: false });
                const { push } = this.props.history;
                push({
                  pathname: "/admin/published",
                });
              }}
            >
              OK
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    writing_categories: state.writing_categories.writing_categories,
    // news: state.news.news,
    admin_add_writing_status: state.message.admin_add_writing_status,
    admin_upload_images_finished_array:
      state.message.admin_upload_images_finished_array,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_writing_categories: () => dispatch(actions.get_writing_categories()),
    add_writing: (payload) => dispatch(actions.add_writing(payload)),
    upload_image: (payload) => dispatch(actions.upload_image(payload)),
    delete_image: (image_name) => dispatch(actions.delete_image(image_name)),
    init_admin_upload_images_finished_array: () =>
      dispatch(
        actions.set_message({
          name: "admin_upload_images_finished_array",
          value: [],
        })
      ),
    update_admin_uploaded_images_array: (new_array) => {
      dispatch(
        actions.set_message({
          name: "admin_upload_images_finished_array",
          value: [...new_array],
        })
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateWriting);
