import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import { NavLink } from "react-router-dom";
import { Row, Col, Input } from "reactstrap";

import name_logo from "../../../images/name_logo.png";
import * as actions from "../../../store/actions/index";
import "./Menu.css";
import "../../../components/css/media/menu.css";
import Tools from "../../../utils/tools";

import {
  INDEX_ROOT_URL,
  PHOTO_ART_ROOT_URL,
  WRITING_ROOT_URL,
  // WRITE_UP_ROOT_URL,
} from "../../../consts/const";

class Menu extends React.Component {
  state = {
    category: "",
  };
  componentDidMount() {
    // console.log("menu", this.props);
  }

  onChangeCategory = (e) => {
    console.log(e.target.value);
    const { value } = e.target;
    const { history } = this.props;
    // this.setState({ category: value });
    if (value) {
      console.log("good");
      const { set_is_gallery_true } = this.props;
      set_is_gallery_true();
      setTimeout(() => {
        history.replace(`/photo-art/gallery?id=${value}`);
      }, 10);
    } else {
      setTimeout(() => {
        history.replace(`/photo-art`);
      }, 10);
    }
  };

  onChangeWritingCategory = (e) => {
    console.log(e.target.value);
    const { value } = e.target;
    const { history } = this.props;
    // this.setState({ category: value });
    setTimeout(() => {
      history.replace(`/writings/${value}`);
    }, 10);
  };

  create_comparator = (key) => {
    return (a, b) => {
      let val_a = a[key];
      let val_b = b[key];

      if (val_a < val_b) {
        return 1;
      }
      if (val_a > val_b) {
        return -1;
      }
      return 0;
    };
  };

  sortByID = () => {
    return (a, b) => {
      if (a.id === "1" && b.id !== "1") {
        return -1;
      }
      return 0;
    };
  };

  render() {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);

    // console.log("fdafsa", window.location.pathname);
    // const path = window.location.pathname;

    // let write_up_active = "";
    // if (path.includes("write-up")) {
    //   write_up_active = "active";
    // }
    // const isPhotoArtPath = path.includes("photo-art");
    const isPhotoArtPath = Tools.is_photoart_domain();
    let photo_art_active = "";
    let items = [];
    let cat_id;
    if (isPhotoArtPath) {
      cat_id = params.get("id");
      if (!cat_id) {
        cat_id = "";
      }
      const { posts } = this.props;
      if (posts.length === 0) {
        return null;
      }
      photo_art_active = "active";
      items = posts.filter((post) => {
        return (
          post.gallery === "1" &&
          post.delete !== "1" &&
          post.category_id !== "1" &&
          post.location_id !== "1"
        );
      });
      console.log("items", items);
      // const { category } = this.state;
      items.sort(this.create_comparator("category_display_id"));
      items.sort(this.sortByID());
    }

    // const isWritingsPath = path.includes("writings");
    const isWritingsPath = Tools.is_writings_domain();
    let writings_active = "";
    let writings_items = [];
    let writing_cat_id;
    if (isWritingsPath) {
      writings_active = "active";
      // writing_cat_id = params.get("id");
      writing_cat_id = this.props.location.pathname.split("/")[2];
      console.log("writing_cat_id", this.props.location.pathname.split("/")[2]);
      if (!writing_cat_id) {
        writing_cat_id = "";
      }
      const { writings } = this.props;
      console.log(writings);
      if (writings.length === 0) {
        return null;
      }

      const writings_1 = writings.filter((item) => {
        return item.delete !== "1" && item.category_id !== "1";
      });

      writings_items = Tools.getUniqueArrayBy(writings_1, "category_id");

      console.log("writings_items", writings_items);
      writings_items.sort(this.create_comparator("category_display_id"));
      writings_items.sort(this.sortByID());
    }

    const is_show_menu = writings_active || photo_art_active;

    return (
      <Row className="menu">
        <Col sm="2">
          {/* <Link to="/">
            <img src={name_logo} alt="name logo" width="150px" />
          </Link> */}
          <a href={INDEX_ROOT_URL}>
            <img src={name_logo} alt="name logo" width="172px" />
          </a>
        </Col>
        <Col sm="5" className="menu-links mobile-photo-art-link">
          {is_show_menu && (
            <>
              {/* <NavLink to={PHOTO_ART_ROOT_URL}> */}
              <a href={PHOTO_ART_ROOT_URL} className="active">
                PHOTO ART <span className="material-icons home-icon">home</span>
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* </NavLink>&nbsp;&nbsp;&nbsp;&nbsp; */}
            </>
          )}
          {is_show_menu && (
            <>
              {/* <NavLink to="/writings"> */}
              <a href={WRITING_ROOT_URL}  className="active">
                WRITINGS <span className="material-icons home-icon">home</span>
              </a>
              {/* </NavLink> */}
            </>
          )}
        </Col>
        {isPhotoArtPath && false && (
          <Col sm="3">
            <div className="form-group has-search">
              {/* <span class="material-icons form-control-feedback">search</span> */}
              <input type="text" className="form-control" />
              <span className="material-icons form-control-feedback right-search">
                search
              </span>
            </div>
          </Col>
        )}
        {isPhotoArtPath && (
          <>
            <Col sm="1"></Col>
            <Col sm="4">
              <div className="form-group category-select">
                {/* <Label for="exampleSelect" sm={2}>
              GALLERY
            </Label> */}
                <div className="select-div">
                  <Input
                    type="select"
                    name="select"
                    id="exampleSelect"
                    // value={category}
                    value={cat_id}
                    onChange={this.onChangeCategory}
                    // onChange={() => { history.push(`/photo-art/gallery?id=${post.category_id}`) }}
                    className="select__field"
                  >
                    {/* <option value={""}>Galleries</option> */}
                    <option value={""}>
                      {cat_id ? "Photo Art Home" : "Galleries"}
                    </option>
                    {items.map((item) => {
                      return (
                        <option key={item.id} value={item.category_id}>
                          {item.category_name}
                        </option>
                      );
                    })}
                  </Input>
                </div>
              </div>
            </Col>
          </>
        )}

        {isWritingsPath && false && (
          <>
            <Col sm="3"></Col>
            <Col sm="4">
              <div className="form-group category-select">
                <div className="select-div">
                  <Input
                    type="select"
                    name="select"
                    id="writingCategorySelect"
                    // value={category}
                    value={writing_cat_id}
                    onChange={this.onChangeWritingCategory}
                    className="select__field"
                  >
                    <option value={""}>Category</option>
                    {writings_items.map((item) => {
                      return (
                        <option key={item.id} value={item.category_id}>
                          {item.category_name}
                        </option>
                      );
                    })}
                  </Input>
                </div>
              </div>
            </Col>
          </>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    posts: state.post.posts,
    writings: state.writing.writings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    set_is_gallery_true: () =>
      dispatch(
        actions.set_message({
          name: "is_gallery",
          value: true,
        })
      ),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
